import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  LogonScreen,
  // ImageScreen,
  LogonScreenAnimation
} from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

import logo from '../../assets/logo.svg';

// interface FormFields {
//   email: string;
//   password: string;
// }

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { signIn, isAdministrator } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .email('Digite um email, formato: nome@dominio')
            .required('Digite um email, formato: nome@dominio'),
          password: Yup.string().min(4, 'Mínimo de 6 caracteres')
        });
        await schemaValidation.validate(data, {
          abortEarly: false
        });

        const email = data.email.toLowerCase();
        const { password } = data;

        await signIn({
          email,
          password
        });

        const administrator = isAdministrator();

        setLoading(false);

        if (administrator) {
          history.push('/dashboardadm');
        } else {
          history.push('/dashboard');
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro de Login',
          description: 'Usuário ou Senha Inválido.'
        });
      }
    },
    [signIn, isAdministrator, history, addToast]
  );

  return (
    <>
      <Container>
        <LogonScreenAnimation>
          <LogonScreen>
            <img src={logo} alt="GoHair" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h2>Acesso</h2>
              <Input name="email" icon={FiMail} placeholder="E-mail" />
              <Input
                name="password"
                type="password"
                icon={FiLock}
                placeholder="Senha"
              />
              <Button loading={loading} type="submit">
                Entrar
              </Button>
              <Link to="/forgotpassword">Esqueceu sua senha?</Link>
            </Form>
            <Link to="/signup">
              <FiLogIn />
              Cadastre-se
            </Link>
          </LogonScreen>
        </LogonScreenAnimation>

        {/* <ImageScreen onClick={handleToggleProvider} selected={provider} /> */}
      </Container>
    </>
  );
};

export default SignIn;
