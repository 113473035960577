import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

interface InputCheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const InputCheckBox: React.FC<InputCheckBoxProps> = ({ name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return <input ref={inputRef} type="checkbox" {...rest} />;
};

export default InputCheckBox;
