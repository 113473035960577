import { ValidationError } from 'yup';

interface Errors {
  [errorname: string]: string;
}

export default function getValidationErrors(error: ValidationError): Errors {
  const validationError: Errors = {};

  error.inner.forEach(err => {
    validationError[err.path] = err.message;
  });

  return validationError;
}
