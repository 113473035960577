import styled, { keyframes } from 'styled-components';

import backgroundImage from '../../assets/sign-up-background.png';

export const Container = styled.div`
  /* height: 100vh; */
  display: flex;
  align-items: stretch;
  /* overflow: hidden; */
`;

export const CreateAccountScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 650px; */

  form {
    @media (max-width: 440px) {
      width: 100%;
    }

    width: 440px;
    padding: 10px 10px 20px;
    text-align: center;
    h1 {
      margin-bottom: 24px;
    }
    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      font-size: 14px;
      font-weight: normal;
      color: #f48fb1;
      input {
        margin-left: 5px;
        cursor: pointer;
        /* opacity: 0.5; */
      }
    }
  }
  > a {
    color: #82b1ff;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
    svg {
      margin-right: 10px;
    }
  }
`;

const fromRightToCenter = keyframes`
  from {opacity: 0; transform: translateX(70px);}
  to {opacity: 1; transform: translateX(0);}
`;

export const CreateAccountScreenAnimation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;

  animation: ${fromRightToCenter} 2s;
`;

const fromLeftToCenter = keyframes`
  from {opacity: 0; transform: translateX(-70px);}
  to {opacity: 1; transform: translateX(0);}
`;

export const ImageScreen = styled.div`
  flex: 1;
  background: url(${backgroundImage}) no-repeat center;
  background-size: cover;
  animation: ${fromLeftToCenter} 2s;
`;

export const CompaniesContainer = styled.div`
  position: absolute;
  /* width: 360px;
  height: 130px; */
  overflow: auto;
  margin-top: -10px !important;
  margin-left: 55px;
  padding: 5px;
  border-radius: 3px;
  background: #c2185b;
`;

export const ListCompanies = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: left;
  color: #000;
  width: 335px;
  &:hover {
    background: #fff;
  }
`;
