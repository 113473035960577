import React, { createContext, useCallback, useState, useContext } from 'react';

import ToastContainer from '../components/ToastContainer';

interface ToastContextData {
  addToast(message: ToastMessage): void;
  removeToast(index: number): void;
}

interface ToastMessage {
  type?: 'success' | 'error';
  title: string;
  description?: string;
}

export const ToastContext = createContext<ToastContextData>(
  {} as ToastContextData
);

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const removeToast = useCallback((index: number) => {
    setMessages(oldState => {
      const newState = [...oldState];
      // newState.splice(index, 1);
      delete newState[index];
      return [...newState];
    });
  }, []);

  const addToast = useCallback(
    (message: ToastMessage) => {
      const toast = {
        type: message.type,
        title: message.title,
        description: message.description
      };

      setMessages(prevState => {
        setTimeout(() => {
          removeToast(prevState.length);
        }, 3000);

        return [...prevState, toast];
      });
    },
    [removeToast]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('Context Provider must be assigned to application.');
  }

  return context;
}
