import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/ToastContext';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  LogonScreen,
  LogonScreenAnimation,
  ImageScreen
} from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

import logo from '../../assets/logo.svg';
import api from '../../services/api';

// interface FormFields {
//   email: string;
//   password: string;
// }

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Digite um email, formato: nome@dominio')
        });
        await schemaValidation.validate(data, {
          abortEarly: false
        });

        const email = data.email.toLowerCase();

        await api.post('/password/forgot', { email });

        addToast({
          type: 'success',
          title: 'Recuperação de Senha',
          description: 'Um email para recuperar a senha foi enviado.'
        });

        // history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro de Recuperação de Senha',
          description: 'Usuário Inválido.'
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast]
  );

  return (
    <>
      <Container>
        <LogonScreenAnimation>
          <LogonScreen>
            <img src={logo} alt="GoHair" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Recuperar Senha</h1>
              <Input name="email" icon={FiMail} placeholder="E-mail" />

              <Button loading={loading} type="submit">
                Recuperar
              </Button>
            </Form>
            <Link to="/">
              <FiArrowLeft />
              Retornar ao Login
            </Link>
          </LogonScreen>
        </LogonScreenAnimation>

        <ImageScreen />
      </Container>
    </>
  );
};

export default ForgotPassword;
