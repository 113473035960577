import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  /* height: 100vh; */
  display: flex;
  align-items: stretch;
  /* overflow: hidden; */
`;

export const CreateAccountScreen = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 650px;

  form {
    padding: 10px 10px 20px;
    /* width: 440px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-bottom: 5px;
      font-size: 20px;
      align-self: flex-start;
    }

    h3 {
      font-size: 14px;
      color: #f48fb1;
      input {
        margin-left: 5px;
        cursor: pointer;
        opacity: 0.5;
        width: 15px;
        height: 15px;
      }
    }

    a {
      color: #f4ede8;
      align-self: flex-start;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: #82b1ff;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

const fromTopToCenter = keyframes`
  from {opacity: 0; transform: translateY(-70px);}
  to {opacity: 1; transform: translateX(0);}
`;

export const CreateAccountScreenAnimation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  animation: ${fromTopToCenter} 2s;
`;

export const AvatarProfile = styled.div`
  /* margin-bottom: 5px; */
  position: relative;
  width: 150px;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  svg {
    width: 80px;
    height: 80px;
  }
  label {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #81b1ff;
    border: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    input {
      display: none;
    }
    svg {
      color: #000;
      width: 17px;
      height: 17px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const DivContainer = styled.div`
  position: relative;
  width: 100%;
  border: 0;
`;

export const CompaniesContainer = styled.div`
  position: absolute;
  margin-top: -18px !important;
  margin-left: 55px;
  padding: 5px;
  border-radius: 3px;
  background: #c2185b;
`;

export const ListCompanies = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: left;
  color: #000;
  width: 330px;
  &:hover {
    background: #fff;
  }
`;
