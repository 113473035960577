import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  mobile: string;
  company_id: string;
  avatar_url: string;
  provider: boolean;
}

interface AuthState {
  token: string;
  user: User;
  administrator: boolean;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  isAdministrator(): boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@gohair:token');
    const user = localStorage.getItem('@gohair:user');
    const administrator = localStorage.getItem('@gohair:administrator');

    if (token && user) {
      api.defaults.headers.authorization = `Baerer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        administrator
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password
    });

    const { token, user, administrator } = response.data;

    localStorage.setItem('@gohair:token', token);
    localStorage.setItem('@gohair:user', JSON.stringify(user));
    localStorage.setItem('@gohair:administrator', administrator);

    api.defaults.headers.authorization = `Baerer ${token}`;

    setData({ token, user, administrator });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@gohair:token');
    localStorage.removeItem('@gohair:user');
    localStorage.removeItem('@gohair:administrator');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@gohair:user', JSON.stringify(user));
      setData({ token: data.token, user, administrator: data.administrator });
    },
    [data.token, data.administrator]
  );

  const isAdministrator = useCallback(() => {
    const administrator = localStorage.getItem('@gohair:administrator');
    const admString = `${administrator}`;

    if (admString === 'true') {
      return true;
    }
    return false;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        isAdministrator,
        signIn,
        signOut,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Context Provider must be assigned to application.');
  }

  return context;
}
