import React from 'react';
import {
  FiAlertCircle,
  FiInfo,
  FiCheckCircle,
  FiXCircle
} from 'react-icons/fi';

import { Container, Toast } from './styles';

import { useToast } from '../../hooks/ToastContext';

interface ToastContainerProps {
  messages: {
    type?: 'success' | 'error';
    title: string;
    description?: string;
  }[];
}

const toastIcon = {
  info: <FiInfo size={25} />,
  success: <FiCheckCircle size={25} />,
  error: <FiAlertCircle size={25} />
};

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const { removeToast } = useToast();

  return (
    <Container>
      {messages.filter(Boolean).map((message, index) => (
        <Toast type={message.type} key={index.toString()}>
          {toastIcon[message.type || 'info']}

          <div>
            <strong>{message.title}</strong>
            <p>{message.description}</p>
          </div>

          <button onClick={() => removeToast(index)} type="button">
            <FiXCircle size={18} />
          </button>
        </Toast>
      ))}
    </Container>
  );
};

export default ToastContainer;
