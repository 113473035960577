import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isToday, format, parseISO, isAfter } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  FiPower,
  FiClock,
  FiUser,
  FiArrowLeftCircle,
  FiArrowRightCircle
} from 'react-icons/fi';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import {
  Container,
  Header,
  HeaderContent,
  Profile,
  Content,
  Schedule,
  Calendar,
  NextAppointment,
  Section,
  Appointment,
  ProviderContainer,
  ProviderContainerItem,
  ProviderAvatar,
  ProviderName,
  ScrollArrows
} from './styles';

import logo from '../../assets/logo.svg';
import { useAuth } from '../../hooks/AuthContext';
import api from '../../services/api';

interface MonthAvailabilityItems {
  day: number;
  available: boolean;
}

interface AppointmentItems {
  id: string;
  date: string;
  hourFormatted: string;
  user: {
    name: string;
    avatar_url: string;
  };
}

interface ProvidersItems {
  id: string;
  name: string;
  avatar_url: string;
}

const DashboardAdm: React.FC = () => {
  const history = useHistory();
  const { signOut, user, isAdministrator } = useAuth();

  if (!isAdministrator()) {
    history.push('/dashboard');
  }

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState<
    MonthAvailabilityItems[]
  >([]);
  const [appointments, setAppointments] = useState<AppointmentItems[]>([]);
  const [providers, setProviders] = useState<ProvidersItems[]>([]);
  const [selectedProvider, setSelectedProvider] = useState('');

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleSelectedProvider = useCallback(key => {
    setSelectedProvider(key);
  }, []);

  useEffect(() => {
    api.get(`/providers/${user.company_id}`).then(response => {
      const apiResponse = response.data;
      const firstProvider = apiResponse[0];
      setProviders(apiResponse);
      setSelectedProvider(firstProvider.id);
    });
  }, [user.company_id]);

  useEffect(() => {
    if (selectedProvider) {
      api
        .get(`/providers/${selectedProvider}/month-availability`, {
          params: {
            year: currentMonth.getFullYear(),
            month: currentMonth.getMonth() + 1
          }
        })
        .then(response => {
          setMonthAvailability(response.data);
        });
    }
  }, [currentMonth, selectedProvider]);

  useEffect(() => {
    if (selectedProvider) {
      api
        .get<AppointmentItems[]>(`/appointments/${selectedProvider}`, {
          params: {
            day: selectedDate.getDate(),
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear()
          }
        })
        .then(response => {
          const appointmentsFormatted = response.data.map(appointment => {
            return {
              ...appointment,
              hourFormatted: format(parseISO(appointment.date), 'HH:mm')
            };
          });
          appointmentsFormatted.sort((current, next) => {
            const currentDate = parseISO(current.date).getTime();
            const nextDate = parseISO(next.date).getTime();

            return currentDate - nextDate;
          });
          setAppointments(appointmentsFormatted);
        });
    }
  }, [selectedDate, selectedProvider]);

  const daysOfWeek = useMemo(() => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'], []);

  const months = useMemo(
    () => [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    []
  );

  const disabledWeekdays = useMemo(() => [{ daysOfWeek: [0] }], []);

  const disabledDays = useMemo(() => {
    const dates = monthAvailability
      .filter(monthDay => !monthDay.available)
      .map(
        monthDay =>
          new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth(),
            monthDay.day
          )
      );
    return dates;
  }, [currentMonth, monthAvailability]);

  const formattedDay = useMemo(
    () => format(selectedDate, "'dia' dd 'de' MMMM", { locale: ptBR }),
    [selectedDate]
  );

  const formattedWeek = useMemo(
    () => format(selectedDate, 'cccc', { locale: ptBR }),
    [selectedDate]
  );

  const morningAppointments = useMemo(
    () =>
      appointments.filter(
        appointment => parseISO(appointment.date).getHours() < 12
      ),
    [appointments]
  );

  const afternoonAppointments = useMemo(
    () =>
      appointments.filter(
        appointment =>
          parseISO(appointment.date).getHours() >= 12 &&
          parseISO(appointment.date).getHours() < 18
      ),
    [appointments]
  );

  const eveningAppointments = useMemo(
    () =>
      appointments.filter(
        appointment => parseISO(appointment.date).getHours() >= 18
      ),
    [appointments]
  );

  const nextAppointment = useMemo(
    () =>
      appointments.find(appointment =>
        isAfter(parseISO(appointment.date), new Date())
      ),
    [appointments]
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <img src={logo} alt="GoHair" />

          <Profile>
            {user.avatar_url ? (
              <img src={user.avatar_url} alt={user.name} />
            ) : (
              <FiUser size={56} />
            )}

            <div>
              <span>Bem vindo,</span>
              <Link to="/profile">
                <strong>{user.name}</strong>
              </Link>
            </div>
          </Profile>

          <button type="button" onClick={signOut}>
            <FiPower size={20} />
          </button>
        </HeaderContent>
      </Header>

      <ProviderContainer>
        <ScrollMenu
          arrowLeft={
            <ScrollArrows>
              <FiArrowLeftCircle />
            </ScrollArrows>
          }
          arrowRight={
            <ScrollArrows>
              <FiArrowRightCircle />
            </ScrollArrows>
          }
          alignCenter={false}
          data={providers.map(provider => (
            <ProviderContainerItem
              key={provider.id}
              selected={selectedProvider === provider.id}
            >
              {provider.avatar_url ? (
                <ProviderAvatar src={provider.avatar_url} alt={provider.name} />
              ) : (
                <FiUser />
              )}
              <ProviderName selected={selectedProvider === provider.id}>
                {provider.name}
              </ProviderName>
            </ProviderContainerItem>
          ))}
          selected={selectedProvider}
          onSelect={handleSelectedProvider}
        />
      </ProviderContainer>
      <Content>
        <Schedule>
          <h2>Horários Agendados</h2>
          <p>
            {isToday(selectedDate) && <span>Hoje</span>}
            <span>{formattedDay}</span>
            <span>{formattedWeek}</span>
          </p>

          {isToday(selectedDate) && nextAppointment && (
            <NextAppointment>
              <strong>Próximo Agendamento</strong>
              <div>
                {nextAppointment.user.avatar_url ? (
                  <img
                    src={nextAppointment.user.avatar_url}
                    alt={nextAppointment.user.name}
                  />
                ) : (
                  <FiUser size={80} />
                )}

                <strong>{nextAppointment.user.name}</strong>
                <span>
                  <FiClock size={20} />
                  {nextAppointment.hourFormatted}
                </span>
              </div>
            </NextAppointment>
          )}

          <Section>
            <strong>Manhã</strong>
            {!morningAppointments.length && (
              <p>Nenhum Agendamento no Período</p>
            )}
            {morningAppointments.map(appointment => (
              <Appointment key={appointment.id}>
                <span>
                  <FiClock />
                  {appointment.hourFormatted}
                </span>
                <div>
                  {appointment.user.avatar_url ? (
                    <img
                      src={appointment.user.avatar_url}
                      alt={appointment.user.name}
                    />
                  ) : (
                    <FiUser size={56} />
                  )}

                  <strong>{appointment.user.name}</strong>
                </div>
              </Appointment>
            ))}

            <strong>Tarde</strong>
            {!afternoonAppointments.length && (
              <p>Nenhum Agendamento no Período</p>
            )}
            {afternoonAppointments.map(appointment => (
              <Appointment key={appointment.id}>
                <span>
                  <FiClock />
                  {appointment.hourFormatted}
                </span>
                <div>
                  {appointment.user.avatar_url ? (
                    <img
                      src={appointment.user.avatar_url}
                      alt={appointment.user.name}
                    />
                  ) : (
                    <FiUser size={56} />
                  )}

                  <strong>{appointment.user.name}</strong>
                </div>
              </Appointment>
            ))}

            <strong>Noite</strong>
            {!eveningAppointments.length && (
              <p>Nenhum Agendamento no Período</p>
            )}
            {eveningAppointments.map(appointment => (
              <Appointment key={appointment.id}>
                <span>
                  <FiClock />
                  {appointment.hourFormatted}
                </span>
                <div>
                  {appointment.user.avatar_url ? (
                    <img
                      src={appointment.user.avatar_url}
                      alt={appointment.user.name}
                    />
                  ) : (
                    <FiUser size={56} />
                  )}

                  <strong>{appointment.user.name}</strong>
                </div>
              </Appointment>
            ))}
          </Section>
        </Schedule>
        <Calendar>
          <DayPicker
            fromMonth={new Date()}
            disabledDays={[...disabledWeekdays, ...disabledDays]}
            modifiers={{ available: { daysOfWeek: [1, 2, 3, 4, 5, 6] } }}
            selectedDays={selectedDate}
            onDayClick={handleDateChange}
            onMonthChange={handleMonthChange}
            weekdaysShort={daysOfWeek}
            months={months}
          />
        </Calendar>
      </Content>
    </Container>
  );
};

export default DashboardAdm;
