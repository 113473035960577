import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 32px;
  background: #560027;
`;

export const HeaderContent = styled.div`
  flex-wrap: wrap;
  /* max-width: 1120px; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin-right: 30px;
    /* height: 80px; */
  }
  button {
    margin-left: auto;
    background: transparent;
    border: 0;
    svg {
      color: #f4ede8;
      &:hover {
        color: #82b1ff;
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  /* margin-left: 80px; */
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  div {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    span {
      color: #df6c7e;
    }
    a {
      text-decoration: none;
      &:hover {
        opacity: 0.5;
      }
      strong {
        color: #82b1ff;
      }
    }
  }
`;

export const Content = styled.div`
  /* max-width: 1120px;
  margin: 64px auto;
  display: flex; */
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;

  @media (min-width: 1024px) {
    max-width: 80%;
    margin-left: 120px;
    flex-wrap: nowrap;
  }
`;

export const Schedule = styled.div`
  flex: 1;
  /* width: auto; */

  padding: 0 10px 20px;
  h2 {
    font-size: 30px;
  }
  p {
    margin-top: 8px;
    color: #82b1ff;
    span + span {
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid #82b1ff;
    }
  }
`;

export const NextAppointment = styled.div`
  margin-top: 64px;
  > strong {
    color: #f48fb1;
    font-size: 20px;
    font-weight: 400;
  }
  div {
    background: #bc477b;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 24px;
    position: relative;
    &::before {
      position: absolute;
      height: 80%;
      width: 2px;
      left: 0;
      top: 10%;
      content: '';
      background: #82b1ff;
    }
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    strong {
      margin-left: 24px;
      font-size: 24px;
    }
    span {
      display: flex;
      align-items: center;
      margin-left: auto;
      color: #f4ede8;
      svg {
        color: #82b1ff;
        margin-right: 5px;
      }
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #f48fb1;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #f48fb1;
    display: block;
    margin-top: 20px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;
  & + div {
    margin-top: 16px;
  }
  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #f4ede8;
    svg {
      color: #82b1ff;
      margin-right: 5px;
    }
  }
  div {
    flex: 1;
    /* width: 540px; */
    background: #bc477b;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 10px;
    margin-left: 24px;
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }
    strong {
      margin-left: 24px;
      color: #f4ede8;
      font-size: 16px;
    }
  }
`;

export const Calendar = styled.div`
  /* width: 380px; */
  margin: 20px 10px;

  .DayPicker {
    /* background: #bc477b; */
    border: solid 1px #fce4ec;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker-Weekday {
    color: #fce4ec;
  }

  .DayPicker,
  .DayPicker-month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #f4ede8;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    /* background: #ad1457; */
    background: none;
  }

  .DayPicker-Day--today {
    font-weight: normal;
    color: #82b1ff;
  }
  .DayPicker-Day--disabled {
    color: #f4ede8 !important;
    opacity: 0.3;
    background: transparent !important;
  }
  .DayPicker-Day--selected {
    background: #ad1457 !important;
    border-radius: 10px;
    color: #fce4ec !important;
  }
`;

export const ProviderContainer = styled.div`
  padding: 12px 0;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    width: 80%;
    margin-left: 120px;
  }
`;

interface ProviderContainerProps {
  selected: boolean;
}
export const ProviderContainerItem = styled.div<ProviderContainerProps>`
  background: ${props => (props.selected ? '#ad145f' : '#560027')};
  display: flex;

  align-items: center;
  padding: 8px 12px;
  margin-right: 16px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 50px;
    height: 50px;
  }
`;
export const ProviderAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

interface ProviderNameProps {
  selected: boolean;
}
export const ProviderName = styled.span<ProviderNameProps>`
  margin-left: 8px;
  font-family: 'RobotoSlab-Medium';
  font-size: 16px;
  color: ${props => (props.selected ? '#232129' : '#fce4ec')};
`;

export const ScrollArrows = styled.div`
  padding: 5px;
  font-size: 30px;
  &:hover {
    cursor: pointer;
  }
`;
