import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiLogIn, FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/ToastContext';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  LogonScreen,
  LogonScreenAnimation,
  ImageScreen
} from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

import logo from '../../assets/logo.svg';
import api from '../../services/api';

// interface FormFields {
//   password: string;
//   password_confirmation: string;
// }

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const history = useHistory();
  const location = useLocation();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schemaValidation = Yup.object().shape({
          password: Yup.string().min(4, 'Mínimo de 6 caracteres'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'Senha de confirmação não bate.'
          )
        });
        await schemaValidation.validate(data, {
          abortEarly: false
        });

        const { password, password_confirmation } = data;
        const token = new URLSearchParams(location.search).get('token');

        if (!token) {
          throw new Error();
        }

        await api.post('/password/reset', {
          password,
          password_confirmation,
          token
        });

        addToast({
          type: 'success',
          title: 'Reset de Senha',
          description: 'Sua nova senha foi confirmada. Pode acessar o sistema.'
        });

        setLoading(false);

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro de Reset de Senha',
          description: 'Aconteceu algum erro no reset da senha.'
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, location.search]
  );

  return (
    <>
      <Container>
        <LogonScreenAnimation>
          <LogonScreen>
            <img src={logo} alt="GoHair" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Redefinir a Senha</h1>
              <Input
                name="password"
                type="password"
                icon={FiLock}
                placeholder="Nova Senha"
              />
              <Input
                name="password_confirmation"
                type="password"
                icon={FiLock}
                placeholder="Confirme a Nova Senha"
              />
              <Button loading={loading} type="submit">
                Alterar a senha
              </Button>
            </Form>
            <Link to="/">
              <FiLogIn />
              Retorna a tela de Login
            </Link>
          </LogonScreen>
        </LogonScreenAnimation>

        <ImageScreen />
      </Container>
    </>
  );
};

export default ResetPassword;
