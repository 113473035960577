import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

export const Container = styled.button`
  background: #82b1ff;
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #000;
  font-weight: bold;
  width: 100%;
  margin-top: 16px;
  transition: opacity 0.2s;
  &::placeholder {
    color: #b6e3ff;
  }
  &:hover {
    opacity: 0.9;
  }
  svg {
    width: 30px;
    height: 30px;
    animation: ${rotate} 2s linear infinite;
  }
`;
