import styled, { keyframes, css } from 'styled-components';

import backgroundImage from '../../assets/sign-in-background.png';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const LogonScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 10px; */
  padding: 0 10px;
  border: 1px solid #f48fb1;
  border-radius: 20px;
  form {
    /* margin: 80px 0 50px; */
    /* width: 340px; */
    text-align: center;
    h2 {
      margin: 20px 0;
    }
    a {
      color: #560027;
      display: block;
      margin: 24px 0;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: #df6c7e;
      }
    }
    input:first-of-type {
      text-transform: lowercase;
    }
  }
  > a {
    margin-bottom: 10px;
    color: #82b1ff;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
    svg {
      margin-right: 10px;
    }
  }
  img {
    width: 90%;
  }
`;

const fromTopToDown = keyframes`
  from {opacity: 0; transform: translateY(-150px)}
  to {opacity: 1; transform: translateY(0)}
`;

export const LogonScreenAnimation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 700px; */

  animation: ${fromTopToDown} 2s;
`;

const fromRightToCenter = keyframes`
  from {opacity: 0; transform: translateX(70px);}
  to {opacity: 1; transform: translateX(0);}
`;

interface ImageScreenProps {
  selected: boolean;
}

export const ImageScreen = styled.div<ImageScreenProps>`
  flex: 1;
  background: url(${backgroundImage}) no-repeat center;
  background-size: cover;
  border: none;
  animation: ${fromRightToCenter} 2s;
  ${props =>
    props.selected &&
    css`
      border: 2px solid #82b1ff;
    `}
`;
