import React, {
  useCallback,
  useRef,
  ChangeEvent,
  useState,
  useMemo,
  useEffect
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMail, FiUser, FiLock, FiCamera } from 'react-icons/fi';
import { FaHotel, FaMobileAlt } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/ToastContext';

import {
  Container,
  CreateAccountScreen,
  CreateAccountScreenAnimation,
  AvatarProfile,
  DivContainer,
  CompaniesContainer,
  ListCompanies
} from './styles';

import Input from '../../components/Input';
import InputCheckBox from '../../components/InputCheckBox';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/AuthContext';

// interface FormFields {
//   name: string;
//   email: string;
//   old_password: string;
//   new_password: string;
//   password_confirmation: string;
// }

interface CompanyItems {
  id: string;
  name: string;
  trade_name: string;
}

const Profile: React.FC = () => {
  const { addToast } = useToast();
  const { user, updateUser, isAdministrator } = useAuth();

  const [loading, setLoading] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [companies, setCompanies] = useState<CompanyItems[]>([]);
  const [search, setSearch] = useState('');
  const [provider, setProvider] = useState(user.provider);
  const [mobile, setMobile] = useState(user.mobile);

  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  useEffect(() => {
    api.get('/companies').then(response => {
      setCompanies(response.data);
    });
  }, []);

  const userCompany = useMemo(() => {
    const getCompany = companies.find(
      company => company.id === user.company_id
    );
    setSearch(
      getCompany?.trade_name === undefined ? '' : getCompany.trade_name
    );
    return getCompany;
  }, [companies, user.company_id]);

  const handleSearch = useCallback(event => {
    setSearch(event.target.value);
  }, []);

  const handleChooseCompany = useCallback(trade_name => {
    formRef.current?.setFieldValue('company', trade_name);
  }, []);

  const items = useMemo(() => {
    return companies
      .filter(company => {
        if (
          company.trade_name.toLowerCase().includes(search.toLowerCase()) ||
          search.length === 0
        )
          return company;
        return false;
      })
      .map(company => {
        return (
          <ListCompanies
            key={company.id}
            onMouseOver={() => handleChooseCompany(company.trade_name)}
          >
            <span>{company.trade_name}</span>
          </ListCompanies>
        );
      });
  }, [companies, handleChooseCompany, search]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const validCompanies = companies.map(company => {
          return company.trade_name;
        });

        const schemaValidation = Yup.object().shape({
          name: Yup.string().required('Digite um nome'),
          email: Yup.string()
            .email()
            .required('Digite um email, formato: nome@dominio'),
          mobile: Yup.string().min(
            14,
            'Digite um número de seu celular válido'
          ),
          old_password: Yup.string(),
          new_password: Yup.string().when('old_password', {
            is: value => value.length > 0,
            then: Yup.string().min(6, 'Mínimo de 6 caracteres'),
            otherwise: Yup.string()
          }),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('new_password'), undefined],
            'Senha de confirmação precisa ser a mesma'
          ),
          company: Yup.string().oneOf(
            validCompanies,
            'Digite um estabelecimento válido'
          )
        });
        await schemaValidation.validate(data, {
          abortEarly: false
        });

        const formCompany = companies.find(
          company => company.trade_name === data.company
        );

        const userProfile = {
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          new_password: data.new_password,
          old_password: data.old_password,
          provider,
          company_id: formCompany?.id
        };

        const response = await api.put('/profile', userProfile);

        updateUser(response.data);

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Alteração de Perfil',
          description: 'Dados atualizados com sucesso.'
        });

        // history.push('dashboard');
        history.goBack();
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Alteração de Perfil',
          description:
            'Aconteceu algum erro no momento da atualização. Tente novamente.'
        });
      }
    },
    [addToast, companies, history, provider, updateUser]
  );

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const formFields = new FormData();
        formFields.append('avatar', e.target.files[0]);
        const response = await api.patch('/users/avatar', formFields);
        updateUser(response.data);
        addToast({
          type: 'success',
          title: 'Atualização de Perfil',
          description: 'Avatar atualizado com sucesso.'
        });
      }
    },
    [addToast, updateUser]
  );

  const handleMobile = useCallback(event => {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    const str = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);

    const result = str.replace(regex, '($1)$2-$3');

    setMobile(result);
  }, []);

  return (
    <>
      <Container>
        <CreateAccountScreenAnimation>
          <CreateAccountScreen>
            <Form
              ref={formRef}
              initialData={{
                name: user.name,
                email: user.email,
                mobile: user.mobile,
                company: userCompany?.trade_name,
                provider: user.provider
              }}
              onSubmit={handleSubmit}
            >
              <Link to={isAdministrator() ? '/dashboardadm' : '/dashboard'}>
                <FiArrowLeft />
              </Link>
              <AvatarProfile>
                {user.avatar_url ? (
                  <img src={user.avatar_url} alt={user.name} />
                ) : (
                  <FiUser />
                )}
                <label htmlFor="avatar">
                  <FiCamera />
                  <input
                    type="file"
                    id="avatar"
                    onChange={handleAvatarChange}
                  />
                </label>
              </AvatarProfile>
              <h1>Meu Perfil</h1>
              <h3>
                Profissional?
                <InputCheckBox
                  name="provider"
                  onChange={() => setProvider(!provider)}
                  defaultChecked={provider}
                />
              </h3>
              <Input name="name" icon={FiUser} placeholder="Nome" />
              <Input name="email" icon={FiMail} placeholder="E-mail" />
              <Input
                name="mobile"
                icon={FaMobileAlt}
                placeholder="Número do Celular"
                value={mobile}
                onChange={event => handleMobile(event)}
              />
              <Input
                name="company"
                icon={FaHotel}
                placeholder="Nome do Estabelecimento"
                onFocus={() => setShowCompanies(true)}
                onBlur={() => setShowCompanies(false)}
                onChange={event => handleSearch(event)}
              />
              {showCompanies && (
                <DivContainer>
                  <CompaniesContainer>{items}</CompaniesContainer>
                </DivContainer>
              )}

              <Input
                name="old_password"
                type="password"
                icon={FiLock}
                placeholder="Senha Atual"
              />
              <Input
                name="new_password"
                type="password"
                icon={FiLock}
                placeholder="Nova Senha"
              />
              <Input
                name="password_confirmation"
                type="password"
                icon={FiLock}
                placeholder="Confirmar Senha"
              />
              <Button loading={loading} type="submit">
                Confirmar Alterações
              </Button>
            </Form>
          </CreateAccountScreen>
        </CreateAccountScreenAnimation>
      </Container>
    </>
  );
};

export default Profile;
