import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/AuthContext';

interface RoutePropsPrivate extends RouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const RouteWrapper: React.FC<RoutePropsPrivate> = ({
  component: Component,
  location,
  isPrivate = false,
  ...rest
}) => {
  const { user } = useAuth();

  if (!user && isPrivate) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return <Route {...rest} render={() => <Component />} />;
};

export default RouteWrapper;
