import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMail, FiUser, FiLock } from 'react-icons/fi';
import { FaHotel, FaMobileAlt } from 'react-icons/fa';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/ToastContext';

import {
  Container,
  CreateAccountScreen,
  CreateAccountScreenAnimation,
  ImageScreen,
  CompaniesContainer,
  ListCompanies
} from './styles';

import Input from '../../components/Input';
import InputCheckBox from '../../components/InputCheckBox';
import Button from '../../components/Button';

import logo from '../../assets/logo.svg';

// interface FormFields {
//   name: string;
//   email: string;
//   password: string;
// }

interface CompanyItems {
  id: string;
  name: string;
  trade_name: string;
}

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [companies, setCompanies] = useState<CompanyItems[]>([]);
  const [search, setSearch] = useState('');
  const [provider, setProvider] = useState(false);
  const [mobile, setMobile] = useState('');

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    api
      .get('/companies', {
        params: { directAccessKey: 'ffa3423286bd51672bd849f05ef341e1' }
      })
      .then(response => {
        setCompanies(response.data);
      });
  }, []);

  const handleSearch = useCallback(event => {
    formRef.current?.setFieldValue('company', event.target.value);
    setSearch(event.target.value);
  }, []);

  const handleChooseCompany = useCallback(trade_name => {
    formRef.current?.setFieldValue('company', trade_name);
  }, []);

  const handleGetCompany = useCallback(() => {
    setShowCompanies(false);
    setSearch(formRef.current?.getFieldValue('company'));
  }, []);

  const items = useMemo(() => {
    return companies
      .filter(company => {
        if (
          company.trade_name.toLowerCase().includes(search.toLowerCase()) ||
          search.length === 0
        )
          return company;
        return false;
      })
      .map(company => {
        return (
          <ListCompanies
            key={company.id}
            onMouseOver={() => handleChooseCompany(company.trade_name)}
          >
            <span>{company.trade_name}</span>
          </ListCompanies>
        );
      });
  }, [companies, handleChooseCompany, search]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const validCompanies = companies.map(company => {
          return company.trade_name;
        });

        const schemaValidation = Yup.object().shape({
          name: Yup.string().required('Digite um nome'),
          email: Yup.string()
            .email()
            .required('Digite um email, formato: nome@dominio'),
          mobile: Yup.string().min(
            14,
            'Digite um número de seu celular válido'
          ),
          password: Yup.string().min(6, 'Mínimo de 6 caracteres'),
          company: Yup.string().oneOf(
            validCompanies,
            'Digite um estabelecimento válido'
          )
        });
        await schemaValidation.validate(data, {
          abortEarly: false
        });

        const userCompany = companies.find(
          company => company.trade_name === data.company
        );

        const user = {
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          password: data.password,
          provider,
          company_id: userCompany?.id
        };

        await api.post('/users', user);

        addToast({
          type: 'success',
          title: 'Cadastro Finalizado',
          description: 'Use o seu usuário e senha para acessar o sistema.'
        });

        setLoading(false);

        history.push('/');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro de Cadastro',
          description:
            'Aconteceu algum erro no momento do cadastro. Tente novamente.'
        });
      }
    },
    [addToast, companies, history, provider]
  );

  const handleMobile = useCallback(event => {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    const str = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);

    const result = str.replace(regex, '($1)$2-$3');

    setMobile(result);
  }, []);

  return (
    <Container>
      <ImageScreen />

      <CreateAccountScreenAnimation>
        <CreateAccountScreen>
          <img src={logo} alt="Go Hair" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu cadastro</h1>
            <Input name="name" icon={FiUser} placeholder="Nome" />
            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input
              name="mobile"
              icon={FaMobileAlt}
              placeholder="Número do Celular"
              value={mobile}
              onChange={event => handleMobile(event)}
            />
            <Input
              name="password"
              type="password"
              icon={FiLock}
              placeholder="Senha"
            />
            <Input
              name="company"
              icon={FaHotel}
              placeholder="Nome do Estabelecimento"
              onFocus={() => setShowCompanies(true)}
              onBlur={handleGetCompany}
              onChange={event => handleSearch(event)}
            />

            {showCompanies && <CompaniesContainer>{items}</CompaniesContainer>}

            <h3>
              Profissional?
              <InputCheckBox
                name="provider"
                onChange={() => setProvider(!provider)}
                defaultChecked={provider}
              />
            </h3>

            <Button loading={loading} type="submit">
              Cadastrar
            </Button>
          </Form>
          <Link to="/">
            <FiArrowLeft />
            Voltar Tela de Acesso
          </Link>
        </CreateAccountScreen>
      </CreateAccountScreenAnimation>
    </Container>
  );
};

export default SignUp;
